body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --heading-heading-xs: "Nunito Sans";

  /* font sizes */
  --heading-heading-xs-size: 16px;
  --text-text-m-regular-size: 14px;
  --heading-heading-m-size: 24px;
  --heading-heading-s-size: 20px;
  --heading-heading-l-size: 32px;
  --heading-heading-xl-size: 40px;
  --heading-heading-2xl-size: 48px;
  --heading-heading-3xl-size: 56px;

  /* Colors */
  --border-neutral-tertiary: #262626;
  --background-neutral-secondary: #1a1a1a;
  --background-neutral-primary: #0d0d0d;
  --background-overlay-white-light: rgba(255, 255, 255, 0.15);
  --background-overlay-black-strong: rgba(0, 0, 0, 0.8);
  --text-neutral-quaternary-inverse: #b3b3b3;
  --text-neutral-primary-inverse: #fff;
  --text-brand-primary-brand-primary: #38eb9a;
  --text-neutral-tertiary-inverse: #ccc;
  --text-neutral-tertiary: #333333;
  --border-neutral-quaternary: #4d4d4d;
  --text-brand-primary-brand-primary-light: #d5fbea;
  --background-brand-secondary-brand-secondary: #20beff;
  --text-neutral-secondary-inverse: #e6e6e6;
  --color-mediumseagreen: #109e5e;

  /* Spacing */
  --xl: 16px;
  --xl1: 64px;
  --s: 16px;
  --xl2: 40px;
  --m: 24px;
  --l: 12px;
  --xs: 12px;
  --l1: 32px;
  --m1: 8px;
  --xs1: 8px;
  --full: 999px;
  --xl3: 24px;
  --xl4: 80px;
  --xl5: 120px;

  /* Gaps */
  --gap-181xl: 200px;
  --gap-61xl: 80px;
  --gap-13xl: 32px;
  --gap-45xl: 64px;
  --gap-base: 16px;
  --gap-21xl: 40px;
  --gap-5xl: 24px;
  --gap-xs: 12px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-13xl: 32px;
  --padding-xl: 20px;
  --padding-5xl: 24px;
  --padding-21xl: 40px;
  --padding-xs: 12px;
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-45xl: 64px;
  --padding-61xl: 80px;

  /* Border radiuses */
  --br-base: 16px;
  --br-xs: 12px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-980xl: 999px;
  --br-5xl: 24px;

  /* Effects */
  --background-blur-xs: blur(8px);
}
